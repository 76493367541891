import { createContext, useContext, ReactNode } from 'react'

interface ChatContextProps {
  data: {
    urls: {
      examplePrompts: string
      chatDetail: string
      chatList: string
      submitMessage: string
      createChat: string
      deleteChat: string
    }
    theme?: string
    user: {
      firstName: string
      lastName: string
    }
  }
  methods?: {
    setTheme: () => void
  }
}

const defaultContext: ChatContextProps = {
  data: {
    theme: 'default',
    user: {
      firstName: 'John',
      lastName: 'Doe'
    },
    urls: {
      examplePrompts: 'https://example.com',
      chatDetail: 'https://example.com/chat-detail',
      chatList: 'https://example.com/chat-list',
      submitMessage: 'https://example.com/submit-message',
      createChat: 'https://example.com/create-chat',
      deleteChat: 'https://example.com/delete-chat'
    }
  },
  methods: {
    setTheme: () => {
      throw 'Not Implemented'
    }
  }
}

const ChatContext = createContext<ChatContextProps>(defaultContext)

export const ChatProvider = ({
  initialState,
  children
}: {
  initialState?: ChatContextProps
  children: ReactNode
}): JSX.Element => {
  return (
    <ChatContext.Provider value={initialState ? initialState : defaultContext}>
      {children}
    </ChatContext.Provider>
  )
}

export const useChatContext = () => {
  return useContext(ChatContext)
}
