import React, { useState } from 'react'
import { IChat } from '../types/models'
import { Popover, HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import s from '../styles/AIChat.scss'

interface SidebarProps {
  chats: IChat[]
  selectedChat: string | undefined | null
  onSelectSession: (id: string) => void
  handleDeleteChat: (id: string) => void
  handleNewChat: () => void
}

const SidebarActions = ({
  collapsed,
  setCollapsed,
  handleNewChat
}: {
  collapsed: boolean
  setCollapsed: (arg: boolean) => void
  handleNewChat: () => void
}) => (
  <p className={s['sidebar-actions']} style={{ background: collapsed ? 'transparent' : '#F5F8FE' }}>
    <span className={s['toggle-sidebar']} onClick={() => setCollapsed(!collapsed)}>
      {collapsed ? <span>&gt;</span> : <span>&lt;</span>}
    </span>
    <span className={s['new-chat']} onClick={handleNewChat}>
      +
    </span>
  </p>
)

const Sidebar: React.FC<SidebarProps> = ({
  chats,
  onSelectSession,
  selectedChat,
  handleNewChat,
  handleDeleteChat
}) => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <>
      {collapsed ? (
        <SidebarActions
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          handleNewChat={handleNewChat}
        />
      ) : null}
      <div className={s['sidebar']} style={collapsed ? { display: 'none' } : {}}>
        <SidebarActions
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          handleNewChat={handleNewChat}
        />
        <ul>
          {chats.map(chat => (
            <li
              key={chat.id}
              onClick={() => onSelectSession(chat.id)}
              style={{ background: selectedChat === chat.id ? 'white' : '', display: 'flex' }}
            >
              <span className={s['chat-name']}>{chat.name}</span>
              <Popover
                trigger={
                  <HoverAction hasNewDesign icon={<IoIosMore />} size="small" className={''} />
                }
                triggerClassName={''}
                actions={[
                  { name: 'delete', text: 'Delete', onClick: () => handleDeleteChat(chat.id) }
                ]}
                border
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default Sidebar
